import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Environment } from './environment.model';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../../../../package.json');

export const environment: Environment = {
  production: false,
  name: 'qa',
  version: packageJson.version,
  hash: packageJson.hash,
  ngrxDevTools: [StoreDevtoolsModule.instrument({ maxAge: 100, connectInZone: true })],
  apolloDevTools: false,
  url: 'https://qa.account.jobcloud.ai',
  translationHash: new Date().getTime().toString(),
  network: {
    jobs: 'https://stage.jobs.ch',
    jobcloud: 'https://www.stage.jobcloud.ch',
    js24: 'https://int.jobscout24.ch',
    jobup: 'https://www.stage.jobup.ch',
  },
  api: {
    core: 'https://api.stage.jobcloud.ai',
    ats: 'https://api.stage.jobcloud.ai/ats',
    internalATS: 'https://ats.stage.jobcloud.ai',
    metadata: 'https://metadata-api-stage.jobcloud.services/api/v1/meta',
    media: 'https://media.stage.jobcloud.ai/media',
    ecom: 'https://ecommerce.stage.jobcloud.ai/shop-api',
    middleware: 'https://middleware.qa.jobcloud.ai',
  },
  auth: {
    clientId: 'szSQ9QZBYV2CA33uBgMqPTJh0xfkawjY',
    domain: 'auth.stage.jobcloud.ai',
    audience: 'marketplace-apis',
    scope: 'openid profile email offline_access',
    connection: 'Username-Password-Authentication',
    redirectUri: 'https://qa.account.jobcloud.ai',
    responseType: 'code',
    skipRedirectCallback: true,
    useRefreshTokens: true,
  },
  sentry: {
    target: 'qa.account.jobcloud.ai',
    tracesSampleRate: 0.2,
  },
};

export const auth = environment.auth;
